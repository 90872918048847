<template>
<page-head-wrapper>
	<!-- 操作栏 -->
	<template v-slot:handles-btns>
		<a-button type="primary" @click="saveNewApp" >
			<i class="e-icon save"></i>保存
		</a-button>
		<a-button class="ml-16" @click="cancelHandle">
			<i class="e-icon close"></i>取消
		</a-button>
	</template>
	<div class="add-form-wrap">
  	<a-form-model
			ref="saveForm"
			:model="saveForm"
			:rules="rules"
			:label-col="{span: 4}" :wrapper-col="{span: 16}"
		>
			<a-form-model-item label="应用名称" prop="applicationName">
				<a-input
					v-model="saveForm.applicationName"
					placeholder="请输入应用名称"
				/>
			</a-form-model-item>
			<a-form-model-item label="应用简称" prop="applicationAbbreviation">
				<a-input
					v-model="saveForm.applicationAbbreviation"
					placeholder="请输入应用简称"
				/>
			</a-form-model-item>
			<a-form-model-item label="所属企业" prop="applicationCompany">
				<a-input
					v-model="saveForm.applicationCompany"
					placeholder="请输入企业名称"
				/>
			</a-form-model-item>
			<a-form-model-item label="联系电话" prop="applicationCellphone">
				<a-input
					v-model="saveForm.applicationCellphone"
					placeholder="请输入联系电话"
				/>
			</a-form-model-item>
			<a-form-model-item label="联系邮箱" prop="applicationEmail">
				<a-input
					v-model="saveForm.applicationEmail"
					placeholder="请输入联系邮箱"
				/>
			</a-form-model-item>
		</a-form-model>
	</div>

</page-head-wrapper>

</template>

<script>
import {validateMobile, validateEmail} from '@/utils/validate'
import {mapGetters} from 'vuex'
import {applicationSave} from '@/api/app-permissions'
export default {
	name: 'app-registry-add',
	keep_alive_tab: true,
	computed: {
    ...mapGetters(['adminId']),
  },
	data() {
		let validatePhone = (rule, value, callback) => {
			value = value.trim()
			if (value === '') {
				callback(new Error('请输入手机号'))
			} else if (!validateMobile(value)) {
				callback(new Error('手机号格式不正确'))
			} else {
				callback();
			}
		}
		let validateApplicationEmail = (rule, value, callback) => {
			value = value.trim()
			if (value === '') {
				callback(new Error('请输入邮箱'))
			} else if (!validateEmail(value)) {
				callback(new Error('邮箱格式不正确'))
			} else {
				callback();
			}
		}
		return {
      saveFormLoading: false,
      saveForm: {
        applicationName: '',
        applicationCompany: '',
        applicationCellphone: '',
        applicationEmail: '',
        applicationAbbreviation: '',
      },
      rules: {
        applicationName: [
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ],
        applicationCompany: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        applicationCellphone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        applicationEmail: [
          { required: true, validator: validateApplicationEmail, trigger: 'blur' },
        ],
        applicationAbbreviation: [
          { required: false, message: '请输入应用简称', trigger: 'blur' },
        ]
      },
		}
	},
	methods: {
		// 新增应用
    saveNewApp(){
      console.log(this.saveForm)
      this.$refs.saveForm.validate(valid => {
        if (valid) {
					this.$showLoading()
          applicationSave({
            ...this.saveForm,
            applicationSubmitAdminId: this.adminId,
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('新增应用成功')
              this.$refs.saveForm.resetFields()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        }
      })
    },
		cancelHandle() {
			console.log('cancelHandle')
			this.closeTabPage()
		}
	}
}
</script>

<style scoped>
.add-form-wrap {
	margin: 0 auto;
	width: 600px;
	padding: 24px 0 64px;
}
</style>